<template>
  <div>
    <el-alert :title="connectStatus" v-if="connectStatus" center show-icon type="error" :closable="false">
    </el-alert>
    <div v-if="active==0">
      <el-divider>设备实时数据</el-divider>
      <el-row type="flex" justify="space-around">
        <el-col :span="11">
          <el-card :body-style="{ padding: '10px',display:'flex'}" class="card">
            <el-image style="width: 48px; height: 48px" src="../../../../images/icons/thermometer.png"></el-image>
            <div style="flex:1;display:flex;flex-direction:column;justify-content: space-between;">
              <div class="cardinfo">温度</div>
              <div class="carddata">{{deviceData32.temperature}}℃</div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="11">
          <el-card :body-style="{ padding: '10px',display:'flex'}" class="card">
            <el-image style="width: 48px; height: 48px" src="../../../../images/icons/inbox-in.png"></el-image>
            <div style="flex:1;display:flex;flex-direction:column;justify-content: space-between;">
              <div class="cardinfo">位移</div>
              <div class="carddata">{{deviceData32.drift}}mm</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-around" style="margin-top:10px;">
        <el-col :span="11">
          <el-card :body-style="{ padding: '10px',display:'flex'}" class="card">
            <el-image style="width: 48px; height: 48px" src="../../../../images/icons/timer.png"></el-image>
            <div style="flex:1;display:flex;flex-direction:column;justify-content: space-between;">
              <div class="cardinfo">下落时间</div>
              <div class="carddata">{{deviceData32.fallTime}}S</div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="11">

        </el-col>
      </el-row>
      <el-divider>试验结果</el-divider>
      <van-cell-group class="cardborder" style="margin:0px 10px;">
        <van-cell title="试件1" is-link @click="handleShiJianClick(0)" :value="deviceData32.drift1+'mm'">
          <template #icon>
            <div :style="'visibility:'+v1+';'">
              <van-icon name="play" color="#1B88EE" class="van-cell__left-icon"></van-icon>
            </div>
          </template>
        </van-cell>
        <van-cell icon="play" title="试件2" is-link @click="handleShiJianClick(1)" :value="deviceData32.drift2+'mm'">
          <template #icon>
            <div :style="'visibility:'+v2+';'">
              <van-icon name="play" color="#1B88EE" class="van-cell__left-icon"></van-icon>
            </div>
          </template>
        </van-cell>
        <van-cell title="试件3" is-link @click="handleShiJianClick(2)" :value="deviceData32.drift3+'mm'">
          <template #icon>
            <div :style="'visibility:'+v3+';'">
              <van-icon name="play" color="#1B88EE" class="van-cell__left-icon"></van-icon>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <el-row type="flex" justify="space-around" style="margin-top: 10px;">
        <el-button type="primary" style="width:120px;height:120px;font-size:20px;" :circle="true" @click="handleStart">开始试验</el-button>
        <el-button type="success" style="width:120px;height:120px;font-size:20px;" :circle="true" @click="handleStart">上传数据</el-button>
      </el-row>
    </div>
    <div v-else-if="active==1">
      <van-empty description="建设中"></van-empty>
    </div>
    <div v-else-if="active==2">
      <van-cell-group title="设备设置">
        <van-cell title="下落时间" value="5S"></van-cell>
        <van-cell title="结束后打印结果">
          <van-switch v-model="ifEndPrint" size="15" @change="saveXpPrint"></van-switch>
        </van-cell>
        <van-field label="打印机序列号" v-show="ifEndPrint" input-align="right" v-model="xpPrintSN" @blur="saveXpPrint" @click-right-icon="scanXpPrint">
          <template #right-icon>
            <van-icon name="scan" color="#1B88EE"></van-icon>
          </template>
        </van-field>
      </van-cell-group>
      <van-cell-group title="上传设置">
        <van-cell title="接口公司名称" is-link></van-cell>
      </van-cell-group>
    </div>
    <van-tabbar v-model="active" @change="handleChange">
      <van-tabbar-item icon="desktop-o">操作</van-tabbar-item>
      <van-tabbar-item icon="search">查询</van-tabbar-item>
      <van-tabbar-item icon="setting-o">设置</van-tabbar-item>
    </van-tabbar>

  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  props: ["serialNumber"],
  data() {
    return {
      xpPrintSN: "",
      ifEndPrint: false,
      active: 0,
      loading: null,
      deviceData32: { temperature: null, fallTime: null, drift: null },
      currentIndex: 0,
      v1: "visible",
      v2: "hidden",
      v3: "hidden",
      status1: 0,
      connectStatus: "",
    };
  },
  methods: {
    initXPPrint() {
      let that = this;
      that.axios
        .post(
          "Base_DeviceConfig/GetXPPrint",
          {},
          {
            params: {
              serialNumber: that.serialNumber,
            },
          }
        )
        .then(function (response) {
          that.ifEndPrint = response.data.data.ifEndPrint;
          that.xpPrintSN = response.data.data.xpPrintSN;
        });
    },
    scanXpPrint() {
      let that = this;
      that.wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"],
        success: function (res) {
          that.xpPrintSN = res.resultStr;
          that.saveXpPrint();
        },
      });
    },
    saveXpPrint() {
      let that = this;
      if (!that.xpPrintSN) {
        //that.$notify({ message: "空序列号", type: "warning" });
        return;
      }
      that.axios
        .post(
          "Base_DeviceConfig/SaveXPPrint",
          {},
          {
            params: {
              serialNumber: that.serialNumber,
              ifEndPrint: that.ifEndPrint,
              xpPrintSN: that.xpPrintSN,
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 101) {
            that.$notify({ message: "保存成功", type: "success" });
          }
        });
    },
    handleShiJianClick(index) {
      let that = this;
      that.currentIndex = index;
      if (index == 0) {
        that.v1 = "visible";
        that.v2 = "hidden";
        that.v3 = "hidden";
      } else if (index == 1) {
        that.v1 = "hidden";
        that.v2 = "visible";
        that.v3 = "hidden";
      } else if (index == 2) {
        that.v1 = "hidden";
        that.v2 = "hidden";
        that.v3 = "visible";
      }
    },
    handleChange() {
      let that = this;
      that.loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
      });
      setTimeout(() => {
        that.loading.close();
      }, 200);
    },
    handleStart() {
      let that = this;
      that.websock.send(
        JSON.stringify({
          deviceType: "ZRD008",
          serialNumber: that.serialNumber,
          command: "33",
          data: that.currentIndex,
        })
      );
    },
  },
  mounted() {
    let that = this;
    //获取公众号扫码的凭证
    that.axios
      .post("OfficialAccount/GetWXScanAPI", { onlyText: window.location.href })
      .then(function (response) {
        that.wx = require("weixin-js-sdk");
        that.wx.config({
          debug: false,
          appId: response.data.data.appId,
          timestamp: response.data.data.timestamp,
          nonceStr: response.data.data.nonceStr,
          signature: response.data.data.signature,
          jsApiList: ["scanQRCode"],
        });
        that.wx.ready(function () {});
        that.wx.error(function (err) {
          alert("error" + JSON.stringify(err));
        });
      });
    //初始化webSocket
    const wsuri = process.env.VUE_APP_WebSocketURL;
    that.websock = new WebSocket(wsuri);
    that.websock.onmessage = function (e) {
      //console.log(e);
      //数据接收
      if (e.data != "0") {
        that.connectStatus = "";
        that.deviceData32 = JSON.parse(e.data);
        if (
          that.deviceData32.status != that.status1 &&
          that.deviceData32.status == 0
        ) {
          if (that.currentIndex < 2) that.currentIndex++;
          else that.currentIndex = 0;
          that.handleShiJianClick(that.currentIndex);
        }
        that.status1 = that.deviceData32.status;
      } else {
        that.connectStatus = "未连接";
        that.deviceData32 = e.data;
      }

      setTimeout(() => {
        that.websock.send(
          JSON.stringify({
            deviceType: "ZRD008",
            serialNumber: that.serialNumber,
            command: "32",
          })
        );
      }, 200);
    };
    that.websock.onopen = function () {
      that.websock.send(
        JSON.stringify({
          deviceType: "ZRD008",
          serialNumber: that.serialNumber,
          command: "32",
        })
      );
    };
    that.websock.onerror = function () {
      that.connectStatus = "服务未启动";
    };
    that.websock.onclose = function () {
      that.connectStatus = "服务未启动";
    };
    //初始化打印机设置
    that.initXPPrint();
  },
};
</script>

<style scoped>
.card {
  border: solid 1px #1b88ee;
}
.cardinfo {
  display: flex;
  flex-direction: row-reverse;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.carddata {
  display: flex;
  flex-direction: row-reverse;
  font-weight: bolder;
  font-size: 20px;
  color: red;
}
.cardborder {
  border: solid 1px #1b88ee;
  border-radius: 10px;
  padding: 0px 10px;
}
</style>